import { useQuery, useInfiniteQuery, useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { TCategory, TBlogPostCommentData } from 'types/blog'

export const useFetchBlogCategories = () => {
  return useQuery<TCategory[]>(['fetchCategories'], async () => {
    const response = await fetch('https://blog.lint.finance/wp-json/wp/v2/categories')
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data || []
  })
}

export const useFetchBlogPosts = (searchTerm?: string, selectedCategory?: number | null) => {
  return useInfiniteQuery(
    ['fetchPosts', searchTerm, selectedCategory],
    async ({ pageParam = 1 }) => {
      let url = `https://blog.lint.finance/wp-json/wp/v2/posts?_embed&page=${pageParam}&per_page=10`

      if (searchTerm) {
        url += `&search=${encodeURIComponent(searchTerm)}`
      }

      if (selectedCategory) {
        url += `&categories=${selectedCategory}`
      }

      const response = await fetch(url)
      const totalPages = parseInt(response.headers.get('X-WP-TotalPages') || '1')
      const data = await response.json()

      return {
        data,
        nextPage: pageParam < totalPages ? pageParam + 1 : undefined,
        totalPages
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage
    }
  )
}
export const useFetchBlogPostDetails = (postId: string) => {
  return useQuery(['fetchPostDetails', postId], async () => {
    const response = await fetch(`https://blog.lint.finance/wp-json/wp/v2/posts/${postId}?_embed`)
    if (!response.ok) {
      throw new Error('Failed to fetch blog post')
    }
    const data = await response.json()
    return data
  })
}

export const usePostBlogComment = () => {
  return useMutation<void, Error, TBlogPostCommentData>(async (data) => {
    const loadingToast = toast.loading('Posting comment...', {
      position: 'top-right'
    })

    try {
      const response = await fetch('https://blog.lint.finance/wp-json/wp/v2/comments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          post: data.postId,
          author_name: data.commentData.name,
          author_email: data.commentData.email,
          author_url: data.commentData.website,
          content: data.commentData.comment
        })
      })

      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message || 'Failed to post comment')
      }

      toast.success('Comment posted successfully!', {
        id: loadingToast,
        duration: 3000,
        position: 'top-right',
        style: {
          background: '#10B981',
          color: '#fff',
          padding: '12px'
        }
      })

      return response.json()
    } catch (error: any) {
      toast.error(error.message || 'Failed to post comment', {
        id: loadingToast,
        duration: 3000,
        position: 'top-right',
        style: {
          background: '#FF4B4B',
          color: '#fff',
          padding: '12px'
        }
      })
      throw error
    }
  })
}
