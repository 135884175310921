import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { useFetchBlogCategories } from 'queries/blog'
import { TFiltersProp } from 'types/blog'
import BlogFilterDrawer from './BlogFilterDrawer'
import {
  Box,
  Tabs,
  TabList,
  TabIndicator,
  Tab,
  HStack,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  InputGroup,
  Input,
  InputLeftElement,
  Image,
  useDisclosure
} from '@chakra-ui/react'

function BlogFilters({ selectedCategory, setSelectedCategory, setSearchTerm }: TFiltersProp) {
  const router = useRouter()
  const [searchIndex, setSearchIndex] = useState(-1)
  const [localSearchTerm, setLocalSearchTerm] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: categories } = useFetchBlogCategories()
  console.log(categories)
  const handleSearchClick = () => {
    setSearchIndex(searchIndex === -1 ? 0 : -1)
  }

  const handleCategorySelect = (categoryId: number | null) => {
    setLocalSearchTerm('')
    setSearchTerm('')
    setSelectedCategory(categoryId)
    const selectedCategory = categories?.find((category) => category.id === categoryId)
    const categorySlug = selectedCategory ? selectedCategory.slug : ''
    setSelectedCategory(categoryId)
    if (categorySlug) {
      router.push(`/blog/category/${categorySlug}`)
    } else {
      router.push('/blog')
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value
    setLocalSearchTerm(term)
  }

  const handleSearchSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchTerm(localSearchTerm)
      router.push(`/blog/search?search=${localSearchTerm}`)
    }
  }

  return (
    <Box display="flex" flexDir="column" gap="24px">
      <Flex
        justify="space-between"
        alignItems="center"
        px={{ base: '3rem', xl: '10rem' }}
        mt="100px">
        {categories && categories.length > 0 && (
          <Tabs
            position="relative"
            variant="unstyled"
            display={{ lg: 'flex', base: 'none' }}
            index={selectedCategory ? categories?.findIndex((c) => c.id === selectedCategory) : -1}>
            <TabList>
              <HStack spacing={{ xl: '16px', lg: '8px' }}>
                {categories.map((category) => (
                  <Tab
                    key={category.id}
                    fontSize="14px"
                    onClick={() => handleCategorySelect(category.id)}
                    color={selectedCategory === category.id ? 'black' : '#75728C'}
                    fontWeight={selectedCategory === category.id ? 'medium' : 'regular'}
                    px={{ xl: '3', base: '1' }}
                    py={1}>
                    {category.name}
                  </Tab>
                ))}
              </HStack>
            </TabList>
            {selectedCategory && (
              <TabIndicator
                mt="28px"
                height="3px"
                bg="#665BE0"
                borderRadius="4px"
                transition="all 0.2s ease-in-out"
              />
            )}
          </Tabs>
        )}
        <Box
          display="flex"
          gap="24px"
          width={{ lg: 'auto', base: 'full' }}
          alignItems="end"
          justifyContent="flex-end">
          <Image
            display={{ lg: 'none', base: 'full' }}
            src="/icons/blog-filter.svg"
            alt="filter icon"
            width="36px"
            height="36px"
            onClick={onOpen}
            cursor="pointer"
          />
          <Image
            src="/icons/blog-search.svg"
            alt="search icon"
            width="36px"
            height="36px"
            onClick={handleSearchClick}
            cursor="pointer"
          />
        </Box>
      </Flex>

      {categories && (
        <BlogFilterDrawer
          isOpen={isOpen}
          onClose={onClose}
          categories={categories}
          selectedCategory={selectedCategory}
          onCategorySelect={handleCategorySelect}
        />
      )}

      <Accordion index={searchIndex} allowToggle>
        <AccordionItem border="none">
          <AccordionButton display="none" />
          <AccordionPanel py="24px" bg="#F1F0F6">
            <InputGroup mx="auto" bg="white" borderRadius="8px" width="500px" height="48px">
              <InputLeftElement
                pointerEvents="none"
                left="16px"
                top="50%"
                transform="translateY(-50%)">
                <Image src="/icons/search.svg" alt="Search" />
              </InputLeftElement>
              <Input
                pl="44px"
                fontSize="14px"
                placeholder="Search and press enter"
                _placeholder={{ color: '#9A99A1' }}
                variant="unstyled"
                value={localSearchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleSearchSubmit}
              />
            </InputGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

export default BlogFilters
