import React from 'react'
import { formatDate, slugify } from 'utils/blog'
import { TBlogPostTextsProps } from 'types/blog'
import { Flex, Text, Link } from '@chakra-ui/react'

function BlogPostTexts({
  id,
  title,
  date,
  excerpt = '',
  titleFontSize,
  excerptFontSize,
  noOfLines
}: TBlogPostTextsProps) {
  return (
    <Flex flexDirection="column" gap="8px">
      <Link href={`/blog/${id}-${slugify(title)}`}>
        <Text
          fontSize={titleFontSize}
          fontWeight="semibold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Link>
      <Text fontSize={'14px'} color="gray.500">
        {formatDate(date)}
      </Text>
      <Text
        fontSize={excerptFontSize}
        color="gray.600"
        noOfLines={noOfLines}
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
    </Flex>
  )
}

export default BlogPostTexts
