import { TCategory } from 'types/blog'
import { Flex, Box } from '@chakra-ui/react'

// Assigning category IDs to specific background and text colors
const categoryColors: { [key: number]: { background: string; text: string } } = {
  38: { background: '#FFF1DB', text: '#FF5500' },
  56: { background: '#FFF1DB', text: '#FF5500' },
  19: { background: '#E1FCF2', text: '#2BB076' },
  18: { background: '#FFEDEC', text: '#C74E5B' },
  4: { background: '#EBEBEF', text: '#1D1D25' },
  35: { background: '#DBDBFF', text: '#665BE0' },
  62: { background: '#D5E5FF', text: '#0B5CF8' },
  54: { background: '#DEF9FF', text: '#3FBDF1' },
  1: { background: '#FFE9F4', text: '#FF2891' }
}
function BlogPostCategoryPill({ categories }: { categories: TCategory[] }) {
  // Get the background and text colors for a category by its ID
  const getCategoryBgAndTextColors = (id: number) => {
    return categoryColors[id] || { background: '#DBDBFF', text: '#665BE0' }
  }

  return (
    <Flex gap="8px" wrap="wrap" alignSelf="flex-start">
      {categories.map((category: TCategory) => (
        <Box
          key={category.id}
          backgroundColor={getCategoryBgAndTextColors(category.id!).background}
          color={getCategoryBgAndTextColors(category.id!).text}
          borderRadius="full"
          px="10px"
          py="6px"
          fontSize="13px"
          fontWeight={500}>
          {category?.name}
        </Box>
      ))}
    </Flex>
  )
}

export default BlogPostCategoryPill
