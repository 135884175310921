import { TFilterDrawerProps } from 'types/blog'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Box
} from '@chakra-ui/react'

function BlogFilterDrawer({
  isOpen,
  onClose,
  categories,
  selectedCategory,
  onCategorySelect
}: TFilterDrawerProps) {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg="white">
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Categories</DrawerHeader>
        <DrawerBody pt={4}>
          <VStack align="stretch" spacing={8}>
            {categories?.map((category) => (
              <Box
                key={category.id}
                justifyContent="flex-start"
                fontSize="14px"
                onClick={() => {
                  onCategorySelect(category.id)
                  onClose()
                }}
                color={selectedCategory === category.id ? '#665BE0' : '#1D1D25'}
                fontWeight={selectedCategory === category.id ? 'medium' : 'regular'}>
                {category.name}
              </Box>
            ))}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default BlogFilterDrawer
