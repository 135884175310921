import { useFetchBlogCategories } from 'queries/blog'
import { TPost, TBlogPostProps, TCategory } from 'types/blog'
import BlogPostImage from './BlogPostImage'
import BlogPostTexts from './BlogPostTexts'
import CategoryPill from './BlogPostCategoryPill'
import { Box, VStack } from '@chakra-ui/react'

export function getPostCategories(post: TPost, categories: TCategory[] = []): TCategory[] {
  if (!post?.categories || !Array.isArray(post.categories)) {
    return []
  }

  return post.categories
    .reduce<TCategory[]>((filteredCategories, id) => {
      const category = categories.find((cat) => cat.id === id)
      if (category) {
        filteredCategories.push({
          id: category.id,
          name: category.name,
          link: category.link
        })
      }
      return filteredCategories
    }, [])
    .filter((category): category is TCategory => category !== null)
}

function BlogPost({ post, size }: TBlogPostProps) {
  if (!post) return null

  const { data: categories } = useFetchBlogCategories()

  const postCategories = getPostCategories(post, categories)
  const featuredImage = post?._embedded?.['wp:featuredmedia']?.[0]?.source_url || ''

  return (
    <Box>
      {size === 'large' && (
        <VStack spacing={6} display="flex" flexDirection="column">
          <BlogPostImage
            src={featuredImage}
            alt={post.title?.rendered}
            width="100%"
            height="430px"
          />
          {postCategories.length > 0 && <CategoryPill categories={postCategories} />}
          <BlogPostTexts
            key={post.id}
            id={post.id}
            title={post.title.rendered}
            date={post.date}
            excerpt={post.excerpt.rendered}
            titleFontSize="24px"
            excerptFontSize="16px"
            noOfLines={2}
          />
        </VStack>
      )}
      {size === 'medium' && (
        <VStack spacing={6} display="flex" flexDirection="column">
          <BlogPostImage
            src={featuredImage}
            alt={post.title?.rendered}
            width="100%"
            height="340px"
          />
          {postCategories.length > 0 && <CategoryPill categories={postCategories} />}
          <BlogPostTexts
            key={post.id}
            id={post.id}
            title={post.title.rendered}
            date={post.date}
            titleFontSize="20px"
            excerptFontSize="14px"
            noOfLines={1}
          />
        </VStack>
      )}

      {size === 'small' && (
        <VStack display="flex" flexDir="row" align="start" gap="8px">
          <Box display="flex" flex="1" flexDir="column" gap="8px">
            {postCategories.length > 0 && <CategoryPill categories={postCategories} />}
            <BlogPostTexts
              key={post.id}
              id={post.id}
              title={post.title.rendered}
              date={post.date}
              titleFontSize="16px"
              excerptFontSize="14px"
              noOfLines={1}
            />
          </Box>

          <BlogPostImage
            src={featuredImage}
            alt={post.title?.rendered}
            width="140px"
            height="140px"
          />
        </VStack>
      )}
    </Box>
  )
}

export default BlogPost
