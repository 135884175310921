import React from 'react'
import { TBlogPostImageProps } from 'types/blog'
import { Image } from '@chakra-ui/react'

function BlogPostImage({ src, alt, width, height }: TBlogPostImageProps) {
  return src ? (
    <Image src={src} alt={alt} width={width} height={height} objectFit="cover" rounded="8px" />
  ) : (
    <Image
      src="/images/blog-post-default-image.png"
      alt={alt}
      width={width}
      height={height}
      objectFit="cover"
      rounded="8px"
    />
  )
}

export default BlogPostImage
